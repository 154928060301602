<main>
    <shared-capital-header [isBtnBack]=true (actionBtnBackOutPut)="goBackFormalization()"></shared-capital-header>
    <shared-section-title title="{{clientResponseFormalization.fullnameClient
        ? clientResponseFormalization.fullnameClient: clientResponseFormalization.nameClient}}" [disabledButton]="false"
        [backRouterLink]="['/advisor-home-novelty-view']"></shared-section-title>
    <div class="content">
        <div>
            <bc-icon size="md" [style.color]="'#2C2A29'" class="icon-hand" aria-hidden="true" aria-label="hand">
                hand
             </bc-icon><br><br>
            <span id="titulo" class="txt-title">El cliente debe vincularse</span>
        </div>
        <br><br>
        <div class="div-info div-flex" *ngIf="((clientValidateResponse?.personType == 'PERSONA NATURAL' &&
        (clientValidateResponse.documentType =='CC'|| clientValidateResponse?.documentType =='CC')))
          && clientValidateResponse.customerManagerStatus != 'EXISTE_DESACTUALIZADO'">
            <div class="div-flex align-inicial width-info1-icon">
                <em id="idea-icon" class="bc-icon em-icon-idea">idea</em>
            </div>
            <div class="div-flex align-final width-info1-text">
                <div>
                    <span class="txt-info1-title">
                        ¿Sabías que ahora tu cliente puede autogestionar su vinculación?
                    </span><br><br>
                    <span class="txt-info1-text">¡Es fácil y rápido! Solo necesita su cédula y completar la
                        información solicitada.
                        <a href="{{linkMessage}}" target="_ blank" class="txt-info1-link"><u>CLICK AQUÍ</u></a> el
                        instructivo, compártelo con tu cliente y en cuestión de minutos quedará vinculado.</span><br>
                    <span class="txt-info1-subtext">*No aplica a empresas, menores de edad y extranjeros.</span>
                </div>
            </div>
        </div>
        <div class="div-info div-flex">
            <div class="div-next-todiv width-info2-text">
                <div>
                    <span class="txt-info2-title">Tipo de identificación</span><br>
                    <span name="txtTypeDocument" class="txt-info2-text">{{clientResponseFormalization.documentType
                        ? clientResponseFormalization.documentType : ''}}</span>
                </div>
            </div>
            <div class="div-next-todiv width-info2-text">
                <div>
                    <span class="txt-info2-title">Número de identificación</span><br>
                    <span name="txtNumberDocument" class="txt-info2-text">{{clientResponseFormalization.documentNumber
                        ? clientResponseFormalization.documentNumber : ''}}</span>
                </div>
            </div>
            <div class="div-next-todiv width-info2-text">
                <div>
                    <span class="txt-info2-title">Nombres y apellidos</span><br>
                    <span name="txtNameClient" class="txt-info2-text">{{clientResponseFormalization.fullnameClient
                        ? clientResponseFormalization.fullnameClient : clientResponseFormalization.nameClient}}</span>
                </div>
            </div>
            <div class="div-next-todiv width-info2-text">
                <div>
                    <span class="txt-info2-title">Actividad económica</span><br>
                    <span name="txtActivityEconomic" class="txt-info2-text">{{clientResponseFormalization.activity
                        ? clientResponseFormalization.activity : ''}}</span>
                </div>
            </div>
        </div>
        <div class="div-info div-flex">
            <app-documents-details class="div-width" [dataResponse]="clientValidateResponse"></app-documents-details>
        </div>
        <div class="div-buttons">
            <button bc-button class="width-button" (click)="goBackFormalization()"
                name="btnIrFormalization" type="button" sizeButton="small">
            Continuar</button>
        </div>
    </div>
    <shared-capital-footer></shared-capital-footer>
</main>
