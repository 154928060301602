import { Injectable } from '@angular/core';
import { Observable, fromEvent } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { URL_SERVICIOS_SALES_ROOM, URL_SERVICIOS_NOVELTIES } from 'src/app/config/config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UploadedFileResponse } from '../../models/uploaded-file-response';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class FileManagmentService {

  private urlEndPoint = URL_SERVICIOS_SALES_ROOM
  private urlEndpointNovelties = URL_SERVICIOS_NOVELTIES;
  private service = '';

  constructor(private http: HttpClient) { }

  fileToBase64(fileToRead: File): Observable<string> {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(fileToRead);
    return fromEvent(fileReader, 'load').pipe(pluck('currentTarget', 'result'));
  }

  openDataFile(data: Blob,name:string){
    saveAs(data, name);
  }

  delete(id:number){
    this.service = `request-property-unit/files/delete/${id}`;
    return this.http.post(`${this.urlEndPoint}/${this.service}`,{});
  }

  preview(service:string){
    const httpOptions = {
      responseType: 'blob' as 'json'
    };
   return  this.http.get(`${this.urlEndPoint}/${service}`,httpOptions);
  }

  previewFromAdvisor(id:number){
    this.service = `request-property-unit/files/advisor/${id}`;
    return this.preview(this.service);
  }

  previewFromBackOffice(id:number){
    this.service = `request-property-unit/files/${id}`;
    return this.preview(this.service);

  }

  upload(formData: FormData) {
    const headers = new HttpHeaders({
      Accept: "application/json",
      "Ignore-Content-Type": "true"
    })
    this.service = `request-property-unit/files/upload`;
    return this.http.post<UploadedFileResponse>(`${this.urlEndPoint}/${this.service}`, formData, {headers});
  }

  previewFileNovelty(id: number) {
    this.service = `noveltyfile/${id}`;
    const httpOptions = {
      responseType: 'blob' as 'json'
    };
    return  this.http.get(`${this.urlEndpointNovelties}/${this.service}`, httpOptions);
  }


}
