<main>
    <shared-capital-header [isBtnBack]=true (actionBtnBackOutPut)="goBackFormalization()"></shared-capital-header>
    <shared-section-title title="{{clientResponseFormalization.fullnameClient 
        ? clientResponseFormalization.fullnameClient: clientResponseFormalization.nameClient}}" [disabledButton]="false"
        [backRouterLink]="['/advisor-home-novelty-view']"></shared-section-title>
    <div class="content" *ngIf="!updateClient">
        <div>
            <bc-icon size="md" [style.color]="'#2C2A29'" class="icon-ok" aria-hidden="true" aria-label="ok">
                ok
            </bc-icon><br><br>
            <span id="titulo" class="txt-title">El cliente está listo para continuar con la<br>vinculación
                al proyecto</span><br><br>
            <span class="txt-subtitle">Esta persona cumple con las condiciones para<br>continuar la asociación de un
                inmueble</span>
        </div>
        <br><br>
        <div class="div-info div-flex">
            <div class="div-next-todiv width-info2-text">
                <div>
                    <span class="txt-info1-title">Tipo de identificación</span><br>
                    <span name="txtTypeDocument" class="txt-info1-text">{{clientResponseFormalization.documentType 
                        ? clientResponseFormalization.documentType : ''}}</span>
                </div>
            </div>
            <div class="div-next-todiv width-info2-text">
                <div>
                    <span class="txt-info1-title">Número de identificación</span><br>
                    <span name="txtNumberDocument" class="txt-info1-text">{{clientResponseFormalization.documentNumber 
                        ? clientResponseFormalization.documentNumber : ''}}</span>
                </div>
            </div>
            <div class="div-next-todiv width-info2-text">
                <div>
                    <span class="txt-info1-title">Nombres y apellidos</span><br>
                    <span name="txtNameClient" class="txt-info1-text">{{clientResponseFormalization.fullnameClient 
                        ? clientResponseFormalization.fullnameClient : clientResponseFormalization.nameClient}}</span>
                </div>
            </div>
            <div class="div-next-todiv width-info2-text">
                <div>
                    <span class="txt-info1-title">Actividad económica</span><br>
                    <span name="txtActivityEconomic" class="txt-info1-text">{{clientResponseFormalization.activity 
                        ? clientResponseFormalization.activity : ''}}</span>
                </div>
            </div>
        </div>
        <div class="div-buttons">
            <button bc-button class="width-button" name="btnContinuar" type="button" sizeButton="small"
            (click)="goBackFormalization()">
                Continuar</button>
        </div>
    </div>

    <div class="content" *ngIf="updateClient">
        <div>
            <bc-icon size="md" [style.color]="'#2C2A29'" class="icon-ok" aria-hidden="true" aria-label="ok">
                ok
            </bc-icon><br><br>
            <span id="titulo" class="txt-title">El cliente está listo para continuar con la<br>vinculación
                al proyecto</span><br><br>
            <span class="txt-subtitle">Se sugiere realizar la actualización de sus datos a
                través de la Sucursal Virtual Personas</span>
        </div>
        <br><br>
        <div class="div-info div-flex">
            <div class="div-next-todiv width-info2-text">
                <div>
                    <span class="txt-info1-title">Tipo de identificación</span><br>
                    <span name="txtTypeDocument" class="txt-info1-text">{{clientResponseFormalization.documentType 
                        ? clientResponseFormalization.documentType : ''}}</span>
                </div>
            </div>
            <div class="div-next-todiv width-info2-text">
                <div>
                    <span class="txt-info1-title">Número de identificación</span><br>
                    <span name="txtNumberDocument" class="txt-info1-text">{{clientResponseFormalization.documentNumber 
                        ? clientResponseFormalization.documentNumber : ''}}</span>
                </div>
            </div>
            <div class="div-next-todiv width-info2-text">
                <div>
                    <span class="txt-info1-title">Nombres y apellidos</span><br>
                    <span name="txtNameClient" class="txt-info1-text">{{clientResponseFormalization.fullnameClient 
                        ? clientResponseFormalization.fullnameClient : clientResponseFormalization.nameClient}}</span>
                </div>
            </div>
            <div class="div-next-todiv width-info2-text">
                <div>
                    <span class="txt-info1-title">Actividad económica</span><br>
                    <span name="txtActivityEconomic" class="txt-info1-text">{{clientResponseFormalization.activity 
                        ? clientResponseFormalization.activity : ''}}</span>
                </div>
            </div>
        </div>

        <div class="div-info2 div-flex">
            <div class="container">
                <div class="div-info div-flex notification-custom">
                    <div class="icon">
                        <bc-icon size="md" class="icon-custom" aria-hidden="true" aria-label="icon-noti-info"
                            type="info">idea</bc-icon>
                    </div>
                    <div>
                        <span class="txt-info2-text">Recomendación</span><br>
                        <span name="txtTypeDocument" class="txt-subtitle ">El comprador debe actualizar sus datos por
                            los canales dispuestos por el Grupo Bancolombia
                        </span>
                    </div>
                </div>
                <div  *ngIf="clientValidateResponse.personType === 'PERSONA NATURAL'">
                    <div class="div-next-todiv width-info3-text">
                        <div>
                            <span class="txt-info1-title txt-info1-text">Actualización a través de la sucursal virtual
                                Personas:</span><br>
                            <span name="txtTypeDocument">Debe ingresar en la sucursal virtual 
                                Personas a la opción
                                “Actualizar mis datos” que encontrará en la parte superior 
                                derecha justo abajo de su nombre.
                            </span>
                        </div>
                    </div>
                    <div class="div-next-todiv width-info3-text">
                        <div>
                            <span class="txt-info1-title txt-info1-text">Actualización a través de sucursal
                                física:</span><br>
                            <span name="txtNumberDocument">Debe acercarse a una sucursal física Bancolombia</span>
                        </div>
                    </div>
                </div>
                <div  *ngIf="clientValidateResponse.personType !== 'PERSONA NATURAL'">
                    <div class="div-next-todiv width-info4-text" 
                    *ngIf="clientValidateResponse.personType === 'PERSONA JURIDICA'">
                        <div>
                            <span class="txt-info1-title txt-info1-text">Actualización a través de sucursal
                                física:</span><br>
                            <span name="txtNumberDocument">
                                Debe acercarse a una sucursal física Bancolombia
                            </span>
                        </div>
                    </div>
                    <div class="div-next-todiv width-info4-text" 
                    *ngIf="clientValidateResponse.personType !== 'PERSONA JURIDICA'">
                        <div>
                            <span class="txt-info1-title txt-info1-text">Actualización a través de sucursal
                                física:</span><br>
                            <span name="txtNumberDocument">Debe acercarse a una sucursal física Bancolombia</span>
                            <app-documents-details class="div-width" [dataResponse]="clientValidateResponse">
                
                            </app-documents-details>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="div-buttons">
            <button bc-button class="width-button" name="btnContinuar" type="button" sizeButton="small" 
            (click)="goBackFormalization()">
                Continuar</button>
        </div>
    </div>

    <shared-capital-footer></shared-capital-footer>
</main>