import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientValidateResponse } from 'src/app/shared/models/clientValidateResponse';
import { CommonFunctions } from 'src/app/shared/models/commonFunctions';
import { DocumentPerson } from 'src/app/shared/models/documentPerson';
import { ClientResponseFormalization } from 'src/app/shared/models/formalization-clients/client-response-formalization';
import { ParamService } from 'src/app/shared/services/param/param.service';

@Component({
  selector: 'app-updated-client',
  templateUrl: './updated-client.component.html',
  styleUrls: ['./updated-client.component.css']
})
export class UpdatedClientComponent implements OnInit {

  public clientValidateResponse:ClientValidateResponse;
  clientResponseFormalization:ClientResponseFormalization = new ClientResponseFormalization();
  commonFunctions: CommonFunctions;
  documentPerson: DocumentPerson;
  tipodocumento: string;

  constructor(private activateRoute: ActivatedRoute,
    public router: Router,public paramService: ParamService){
      this.paramService.getValidarCliente().pipe().subscribe(data => {this.clientValidateResponse = data;}); 
      this.commonFunctions = new CommonFunctions();
    }


  updateClient:boolean;

  ngOnInit(): void {
    const type = this.activateRoute.snapshot.paramMap.get("typeBlocking");
    if(type === "success-update"){
      this.updateClient = true
    }else if(type === "success"){
      this.updateClient = false;
    }else{
      this.router.navigate(["/advisor-home-novelty-view"]);
    } 
    
    this.clientResponseFormalization.nameClient= this.clientValidateResponse.firstName;
    this.clientResponseFormalization.fullnameClient= this.clientValidateResponse.fullName;
    this.clientResponseFormalization.documentType = this.clientValidateResponse.documentType;
    this.clientResponseFormalization.documentNumber = this.clientValidateResponse.documentNumber;
    this.clientResponseFormalization.activity = this.clientValidateResponse.activity;

    this.handlePersonType();
  }

  goBackFormalization(){
    this.router.navigate(["/advisor-home-novelty-view", this.clientValidateResponse.noveltyId]);
  }

  handlePersonType() {
    if (this.clientValidateResponse.personType != "PERSONA NATURAL"
        && this.clientValidateResponse.personType != "PERSONA JURIDICA") {
        this.documentPerson = this.commonFunctions.getDocumentPerson(this.tipodocumento);
        this.clientValidateResponse.personType = this.documentPerson.personType;
    } else {
        this.documentPerson = this.commonFunctions.getDocumentPerson(this.tipodocumento);
        this.tipodocumento = this.documentPerson.documentDescribe;
    }
}
}
