import { Component, Injector, OnInit, ViewChild } from "@angular/core";
import { MsalService } from "@azure/msal-angular";
import { BcPopOverComponent } from "@bancolombia/design-system-web/bc-pop-over";
import { ENDPOINTS } from "src/app/config/endpoints";
import { UserMenuCommunicationService } from "src/app/modules/administrator/services/user-menu-comunication/user-menu-communication.service";
import { ModalClaveActualComponent } from "src/app/shared/components/modal/modal-clave-actual/modal-clave-actual.component";
import { Rol } from "src/app/shared/models/Rol";
import { TokenAdmin } from "src/app/shared/models/TokenAdmin";
import { BcLoadingService } from "src/app/shared/services/commons/bc-loading/bc-loading.service";
import { BcModalServiceService } from "src/app/shared/services/commons/bc-modal-service/bc-modal-service.service";
import { LogOutService } from "src/app/shared/services/logOut/log-out.service";
import { ParamService } from "src/app/shared/services/param/param.service";
import { HttpClientService } from "../../services/http-client/http-client.service";
import { Router } from '@angular/router';

const ISASESOR = "Asesor Sala de Ventas";
const ISCOORDINADOR = "Coordinador";
const ROUTENEWS = "advisor-publication";
const ISTRUE = true;

@Component({
  selector: "shared-capital-user-menu",
  templateUrl: "./capital-user-menu.component.html",
  styleUrls: ["./capital-user-menu.component.css"],
})
export class CapitalUserMenuComponent implements OnInit {

  @ViewChild('idPrueba')
  bcPopOverComponent!: BcPopOverComponent;

  userName: string;
  role: string;
  roles: Rol[];
  tokenAdmin = new TokenAdmin();
  isnotification: boolean = false;
  isSesion: boolean = false;
  public listNotification: any[] = [];
  isNotificationRol: boolean = false;
  public router: Router;


  constructor(
    public logOutService: LogOutService,
    private bcModalServiceService: BcModalServiceService,
    public paramService: ParamService,
    private authService: MsalService,
    private bcLoadingService: BcLoadingService,
    private userMenuCommunicationService: UserMenuCommunicationService,
    private httpClientService: HttpClientService,
    private injector : Injector
  ) {
    this.router = injector.get<Router>(Router);
  }


  ngOnInit() {
    this.isnotification = false;
    this.userName = this.getUserName();
    this.role = this.getRolUser();
    this.userMenuCommunicationService.getTemporaryVariable() && this.toogle();
    this.isCoordinadorOrAsesor();
  }

  isCoordinadorOrAsesor() {
    if(this.role==ISCOORDINADOR || this.role==ISASESOR){
      this.isNotificationRol=ISTRUE;
    }
  }

  getUserName(): string {
    return (
      this.tokenAdmin.getUserLoggeIn().firstName.split(" ")[0] +
      " " +
      this.tokenAdmin.getUserLoggeIn().lastName.split(" ")[0]
    );
  }

  getRolUser(): string {
    return this.tokenAdmin.getPermission()
      ? this.tokenAdmin.getPermission()["name"]
      : null;
  }

  isAdp(): boolean {
    return this.authService.getAccount() != null;
  }

  changePassword() {
    this.paramService.setEmail(this.tokenAdmin.getUserLoggeIn().emailAddr);
    this.bcModalServiceService.show(ModalClaveActualComponent);
  }


  exit() {
    this.logOutService.logOutTokenSession();
  }

  goToListNews(){
    this.router.navigate([ROUTENEWS]);
  }
  isAdvisorPublicationRoute() {
    return this.router.url.includes(ROUTENEWS);
  }
  toogle() {
    this.isnotification = !this.isnotification;
    this.isnotification ? this.loadNotifications() : this.bcPopOverComponent.closePopOver();
  }


  loadNotifications() {
      this.httpClientService.get<any[]>(ENDPOINTS.alladvisornotification).subscribe(
        data => {
          this.listNotification = data;
          this.userMenuCommunicationService.setTemporaryVariable(false);
            this.bcPopOverComponent.tooglePopOver();
        },
        error => {
        }
      );
    }
}
