import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ENDPOINTS } from 'src/app/config/endpoints';
import { TokenAdmin } from 'src/app/shared/models/TokenAdmin';
import { NotificationStatus } from 'src/app/shared/models/helpcenter/notification-status/notification-status';
import { NotificationType } from 'src/app/shared/models/helpcenter/notification-type/notification-type';
import { HttpClientService } from 'src/app/shared/services/http-client/http-client.service';

const TEN: number = 10;

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private tokenAdmin: TokenAdmin = new TokenAdmin();
  private httpHeaders: any;
  private token: string;

  constructor(private http: HttpClient, private httpClientService: HttpClientService) {}


  getNotificationTypes(): Observable<Array<NotificationType>> {
    return this.httpClientService.get<Array<NotificationType>>(ENDPOINTS.allTypesNotification);
  }

  getNotificationStatus(): Observable<Array<NotificationStatus>> {
    return this.httpClientService.get<Array<NotificationStatus>>(ENDPOINTS.allStatusNotification);
  }


}
