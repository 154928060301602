import { Component, Injector, Input } from '@angular/core';
import { ENDPOINTS } from 'src/app/config/endpoints';
import { NotificationService } from 'src/app/modules/administrator/services/notification/notification.service';
import { AlertService } from '../../services/alert/alert.service';
import { BcLoadingService } from '../../services/commons/bc-loading/bc-loading.service';
import { FileManagmentService } from '../../services/file-managment/file-managment.service';
import { HttpClientService } from '../../services/http-client/http-client.service';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.css']
})
export class NotificationListComponent {

  @Input() listNotification: any[] = [];
  fileManagmentService: FileManagmentService;

  constructor(
    private httpClientService: HttpClientService,
    public notificationService: NotificationService,
    private bcLoadingService:BcLoadingService,
    private alertService: AlertService,
    private injector : Injector
  ) {
    this.fileManagmentService = injector.get<FileManagmentService>(FileManagmentService);
  }

  openFile(dataPreview: any) {
    if (dataPreview) {
      if (dataPreview?.link && dataPreview?.link?.startsWith('http')) {
        window.open(dataPreview.link, '_blank');
      }else{
        this.bcLoadingService.show();
        this.loadDocumentNotification(dataPreview.id);
      }
    }
  }

  loadDocumentNotification(id: any) {
    const httpOptions = {
      responseType: "blob" as "json",
    };
    this.httpClientService
    .get(
      ENDPOINTS.documentNotification,
      new Map([["id", id.toString()]]),
      httpOptions
    )
    .subscribe((data: Blob) => {
      this.dowloadFile(data);
      this.bcLoadingService.close();
    });
  }

  dowloadFile(data: any){
    this.fileManagmentService.openDataFile(
      data,
      "Documento"
    );
  }

  ngOnInit(): void {
    this.sortNotificationsByDate();
  }

  sortNotificationsByDate(): void {
    this.listNotification.sort((a, b) => {
      const dateA = new Date(a.startDate);
      const dateB = new Date(b.startDate);
      return dateB.getTime() - dateA.getTime();
    });
  }


  isTodayOrThreeDaysAgo(dateString: string): boolean {
    const currentDate = new Date();
    const notificationDate = new Date(dateString);
    const INT_NUMBER_3 = 3;

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const threeDaysAgo = new Date();
    threeDaysAgo.setDate(threeDaysAgo.getDate() - INT_NUMBER_3);
    threeDaysAgo.setHours(0, 0, 0, 0);

    return (
      notificationDate >= threeDaysAgo && notificationDate <= currentDate
    );
  }
}
