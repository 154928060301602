<main>
    <shared-capital-header [isBtnBack]=true (actionBtnBackOutPut)="goBackFormalization()"></shared-capital-header>
    <shared-section-title title="{{clientResponseFormalization.fullnameClient 
        ? clientResponseFormalization.fullnameClient: clientResponseFormalization.nameClient}}" [disabledButton]="false"
        [backRouterLink]="['/advisor-home-novelty-view']"></shared-section-title>
    <div class="content" *ngIf="!mayorGestion">
        <div>
            <bc-icon size="md" [style.color]="'#2C2A29'" class="icon-hand" aria-hidden="true" aria-label="hand">
                hand
            </bc-icon><br><br>
            <span id="titulo" class="txt-title">Para la siguiente fase del proyecto no<br>podremos realizar la
                vinculación</span><br><br>
            <span class="txt-subtitle">Esto por políticas internas del Grupo bancolombia.</span>
        </div>
        <br><br>
        <div class="div-info div-flex">
            <div class="div-next-todiv width-info2-text">
                <div>
                    <span class="txt-info1-title">Tipo de identificación</span><br>
                    <span name="txtTypeDocument" class="txt-info1-text">{{clientResponseFormalization.documentType 
                        ? clientResponseFormalization.documentType : ''}}</span>
                </div>
            </div>
            <div class="div-next-todiv width-info2-text">
                <div>
                    <span class="txt-info1-title">Número de identificación</span><br>
                    <span name="txtNumberDocument" class="txt-info1-text">{{clientResponseFormalization.documentNumber 
                        ? clientResponseFormalization.documentNumber : ''}}</span>
                </div>
            </div>
            <div class="div-next-todiv width-info2-text">
                <div>
                    <span class="txt-info1-title">Nombres y apellidos</span><br>
                    <span name="txtNameClient" class="txt-info1-text">{{clientResponseFormalization.fullnameClient 
                        ? clientResponseFormalization.fullnameClient : clientResponseFormalization.nameClient}}</span>
                </div>
            </div>
            <div class="div-next-todiv width-info2-text">
                <div>
                    <span class="txt-info1-title">Actividad económica</span><br>
                    <span name="txtActivityEconomic" class="txt-info1-text">{{clientResponseFormalization.activity 
                        ? clientResponseFormalization.activity : ''}}</span>
                </div>
            </div>
        </div>
        <div class="div-info">
            <div>
                <span class="txt-info2-title">
                    Teniendo en cuenta lo anterior:
                </span><br><br>
                <span class="txt-info2-text">Es necesario tramitar un desistimiento o una cesión de derechos
                    (en caso que la unidad cuente con más compradores a los que se les pueda realizar la cesión). ​Si
                    requieren información más detallada, comunícate con los administradores del negocio en Fiduciaria
                    Bancolombia. ​
                </span>
            </div>
        </div>
        <div class="div-buttons">
            <button bc-button class="width-button" name="btnFinalizar" type="button" sizeButton="small"
            (click)="goBackFormalization()">
                Finalizar</button>
        </div>
    </div>

    <div class="content" *ngIf="mayorGestion">
        <div>
            <bc-icon size="md" [style.color]="'#2C2A29'" class="icon-hand" aria-hidden="true" aria-label="hand">
                hand
            </bc-icon><br><br>
            <span id="titulo" class="txt-title">Para continuar con el proceso necesitamos​<br>
                más información del cliente</span><br><br>
            <span class="txt-subtitle">Esto por políticas internas del Grupo bancolombia.</span>
        </div>
        <br><br>
        <div class="div-info div-flex">
            <div class="div-next-todiv width-info2-text">
                <div>
                    <span class="txt-info1-title">Tipo de identificación</span><br>
                    <span name="txtTypeDocument" class="txt-info1-text">{{clientResponseFormalization.documentType 
                        ? clientResponseFormalization.documentType : ''}}</span>
                </div>
            </div>
            <div class="div-next-todiv width-info2-text">
                <div>
                    <span class="txt-info1-title">Número de identificación</span><br>
                    <span name="txtNumberDocument" class="txt-info1-text">{{clientResponseFormalization.documentNumber 
                        ? clientResponseFormalization.documentNumber : ''}}</span>
                </div>
            </div>
            <div class="div-next-todiv width-info2-text">
                <div>
                    <span class="txt-info1-title">Nombres y apellidos</span><br>
                    <span name="txtNameClient" class="txt-info1-text">
                        {{clientResponseFormalization.fullnameClient 
                        ? clientResponseFormalization.fullnameClient : clientResponseFormalization.nameClient}}
                    </span>
                </div>
            </div>
            <div class="div-next-todiv width-info2-text">
                <div>
                    <span class="txt-info1-title">Actividad económica</span><br>
                    <span name="txtActivityEconomic" 
                    class="txt-info1-text">{{clientResponseFormalization.activity 
                        ? clientResponseFormalization.activity : ''}}</span>
                </div>
            </div>
        </div>

        <div class="div-info div-flex">
            <div class="container">
                <div *ngIf="clientValidateResponse.controlListStatus === 'ALERTA'"
                 class="div-next-todiv width-info4-text">
                    <app-documents-details [dataResponse]="clientValidateResponse"></app-documents-details>
                </div>

                <div *ngIf="clientValidateResponse.controlListStatus !== 'ALERTA' &&
                 clientResponseFormalization.isHighlyRiskCustomer" 
                 class="div-next-todiv width-info3-text">
                    <span class="txt-info1-title txt-info1-text">Documentos para persona {{typePerson}}:</span>
                    <ul class="missing-documents-list">
                        <li *ngFor="let doc of documents" class="document-item">
                            <em id="document-icon" class="bc-icon">document</em>
                            <span *ngIf="getLink(doc.id) !== undefined" class="txt-document-content">
                                {{doc.description}}.&nbsp;&nbsp;
                                <a target="_ blank" [href]="getLink(doc.id)" 
                                class="txt-document-content-link"><u>Click
                                        aquí</u></a>
                            </span>
                            <span *ngIf="getLink(doc.id) === undefined" 
                            class="txt-document-content">
                            {{doc.description}}.
                        </span>
                        </li>
                    </ul>
                </div>
        
                <div *ngIf="clientValidateResponse.controlListStatus !== 'ALERTA' &&
                 clientResponseFormalization.isHighlyRiskCustomer" 
                 class="div-next-todiv width-info3-text">
                    <div class="informative">
                        <em id="info-icon" class="bc-icon">info</em>
                        <span class="txt-document-subtitle">Requerimos conocer mejor al cliente</span>
                    </div>
                    <p class="txt-document-content">
                        Es necesario que el cliente gestione una documentación adicional
                        para ser enviada en la solicitud de vinculación, a la vez que un
                        representante del Grupo Bancolombia se pondrá en contacto con él para continuar con el proceso:
                    </p>
                    <ul class="missing-documents-list document-item">
                        <em id="document-icon" class="bc-icon">document</em>
                        <a [href]="getAditionalDocuments()" 
                        target="_blank" class="txt-document-content-link">Documentos
                            adicionales</a>
                    </ul>
                </div>

                
            </div>
        
        
        </div>

        <div class="div-buttons">
            <button bc-button class="width-button" name="btnFinalizar" type="button" sizeButton="small"
            (click)="goBackFormalization()">
                Finalizar</button>
        </div>
    </div>

    <shared-capital-footer></shared-capital-footer>
</main>